import React, { useState, useRef } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import logo from '../../assets/images/site-logo-yellow.png';
import register_img from '../../assets/images/register-img.png';
import { Link, useNavigate } from 'react-router-dom';
import Form from "react-validation/build/form";
import { forgot_password } from "../../actions/auth";
import { useDispatch } from "react-redux";
import { Formik, Field, ErrorMessage } from 'formik';
import { ForgotPasswordSchema } from './commonValidation'


function ForgotPassword() {
  const form = useRef();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);


  const handleRegister = (values, { setSubmitting }) => {
    setLoading(true);
    dispatch(forgot_password(values.email, values.mobile_no))
      .then(() => {

      })
      .catch(() => {
        setSubmitting(false);
        setLoading(false);
      });
  };


  return (
    <div>
      <section className='login-section'>
        <div className='circle-top-left'></div>
        <div className='circle-bottom-left'></div>
        <div className='pattern-top-center'></div>
        <div className='pattern-top-right'></div>
        <div className='pattern-bottom-right'></div>
        <div className='pattern-bottom-right'></div>
        <Container className='relative'>
          <Row className='login-inner v-center ptb-100'>

            <Col xs={12} md={12} lg={6} className='left-block'>
              <div className='login-img-wrapper'>
                <div className='logo-block text-center ptb-30 pt-0'>
                  <img src={logo} alt='' />
                </div>
                <div className='login-imgbox'>
                  <img src={register_img} alt='' />
                </div>
                <div className='glow-box'></div>
              </div>
            </Col>

            <Col xs={12} md={12} lg={6} className='right-block d-flex'>
              <div className='form-wrapper ml-auto mr-auto'>
                <div className='logo-block text-center ptb-30 hide show_mobi_view'>
                  <img src={logo} alt='' />
                </div>
                <h2 className='tl'>Find Your Account</h2>
                <Formik
                  initialValues={{
                    email: '',
                    mobile_no: '',
                  }}
                  validationSchema={ForgotPasswordSchema}
                  onSubmit={handleRegister}
                >
                  {({ handleSubmit, handleChange, values, isSubmitting }) => (
                    <Form onSubmit={handleSubmit} ref={form} className='form-inner d-flex'>
                      {/* <div className='input-field full-width'>
                        <label className='label'>Enter Email ID</label>
                        <Field type='email'
                          placeholder='Enter Email ID'
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                        />
                        <ErrorMessage name="email" component="div" className="alert alert-danger mt-2" />
                      </div> */}
                      <div className='input-field full-width'>
                        <label className='label'> Enter Mobile No.</label>
                        <Field type='text'
                          inputmode="numeric"
                          placeholder='Enter Mobile No.'
                          name="mobile_no"
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(/\D/g, '');
                            e.target.value = e.target.value.slice(0, 10);
                          }}
                          value={values.mobile_no}
                          onChange={handleChange}
                        />
                        <ErrorMessage name="mobile_no" component="div" className="alert alert-danger mt-2" />
                      </div>


                      <div className='input-field full-width form-btnbox mb-0'>
                        <Button className='btn secondary w-100' variant="primary" type="submit">Submit</Button>
                      </div>
                    </Form>
                  )}
                </Formik>

                {/* <div className='link-box ptb-20'>
                  <p><u>Please check your email after click on submit button.</u></p>
                </div> */}
                <div className='or-devider'><span>OR</span></div>
                <div className='link-box'>
                  <p >You have an account? <Link to='/login'>Login to your account</Link></p>
                </div>

              </div>
            </Col>

          </Row>
        </Container>
      </section>
    </div>
  )
}

export default ForgotPassword