import React from 'react';

function UserInfo() {
  const user = JSON.parse(localStorage.getItem("user"));
  return (
    <div className='white_box account-blocks h-100'>

      <div className='box-title'>
        <h4>User Information</h4>
      </div>
      
      <div className='user-info-block'>

        <div className='user-sub-items d-flex'>
          <label className='user-label'>Full Name :</label>
          <span className='user-value'>{user?.user.full_name}</span>
        </div>
        <div className='user-sub-items d-flex'>
          <label className='user-label'>Phone Number :</label>
          <span className='user-value'>+ 91 {user?.user.mobile_no}</span>
        </div>

        <div className='user-sub-items d-flex'>
          <label className='user-label'>Email ID :</label>
          <span className='user-value'>{user?.user.email_id}</span>
        </div>
        {/* <div className='user-sub-items d-flex'>
          <label className='user-label'>Date of Birth :</label>
          <span className='user-value'>{user?.user.date_of_birth}</span>
        </div> */}

        {/*<div className='user-sub-items d-flex'>*/}
        {/*  <label className='user-label'>City :</label>*/}
        {/*  <span className='user-value'>Ahmedabad</span>*/}
        {/*</div>*/}

        {/*<div className='user-sub-items d-flex'>*/}
        {/*  <label className='user-label'>State :</label>*/}
        {/*  <span className='user-value'>Gujarat</span>*/}
        {/*</div>*/}

        {/*<div className='user-sub-items d-flex'>*/}
        {/*  <label className='user-label'>Country :</label>*/}
        {/*  <span className='user-value'>{user.email_id}</span>*/}
        {/*</div>*/}

      </div>

    </div>
  )
}
export default UserInfo