import React, { useState, useEffect, useRef, useCallback } from 'react';
import DashboardHeader from '../include/DashboardHeader';
import DashboardSidebar from '../include/DashboardSidebar';
import DashboardTitle from './dashboard_modules/DashboardTitle';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Modal } from 'react-bootstrap'
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import box_img1 from '../../assets/images/icon_appoint.svg';
import box_img2 from '../../assets/images/icon_order.svg';
import box_img3 from '../../assets/images/icon_photo.svg';
import box_img4 from '../../assets/images/icon_emioption.svg';
import thankyou_icon from '../../assets/images/thanks_shapes_r.svg';
import Slider from 'react-slick';
import { logout } from "../../actions/auth";
import { useDispatch } from "react-redux";
import useAxiosInstance from '../../Api/AxiosInstance';

const loadScript = (src) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => resolve(script);
    script.onerror = () => reject(new Error(`Script load error for ${src}`));
    document.head.appendChild(script);
  });
};

const setting1 = {
  dots: false,
  navs: true,
  infinite: true,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 1679,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    }
  ]
};


function Dashboard() {
  const dispatch = useDispatch();
  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);
  let navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [sliderimg, setSliderimg] = useState([])
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => { setShow2(false); setFile1(""); setFileError(''); changeclass1('') }
  const handleShow2 = () => setShow2(true);
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [file1, setFile1] = useState("");
  const [fileError, setFileError] = useState("");
  const [file, setFile] = useState();
  const [myclass1, changeclass1] = useState("");

  const AxiosInstance = useAxiosInstance()

  useEffect(() => {
    loadScript("https://cdn.trustindex.io/loader.js?906599d1520f8065485696d3394").then(() => {
      if (window.Trustindex) {
        window.Trustindex.init();
      }
    }).catch(error => {
      console.error('Error loading Trustindex script:', error);
    });
  }, []);



  function handleChange1(e) {
    const selectedFile = e.target.files[0];
    const allowedTypes = ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];

    if (selectedFile && allowedTypes.includes(selectedFile.type)) {
      setFileError('');
      setFile1(selectedFile.name);
      setFile(selectedFile);
      changeclass1(`file-uploaded`); // Set the appropriate class for styling
    } else {
      setFileError('Only PDF, DOC, and DOCX files are allowed');
    }
  }

  const form = useRef();

  const fetchImage = async () => {
    try {
      const response = await AxiosInstance.post('sliders')
      setSliderimg(response.data.data.slider)

    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    fetchImage()
  }, [])


  const handleSubmit = async (e) => {

    e.preventDefault();
    if (!file1) {
      setFileError("Please upload prescription document.");
      return;
    }

    const formData = new FormData();
    form.current.validateAll();
    formData.append("prescription_pdf", file);


    try {
      const response = await AxiosInstance.post("upload-priscription" , formData)
      if (response.data.status == 200) {
        handleClose2();
        handleShow3();
        changeclass1('')
      }

    } catch (error) {

    }
  
  };
  return (
    <div className='main-page bg4'>
      <DashboardHeader />
      <DashboardSidebar />

      <section className='main-section'>
        <div className='main-inner'>

          <DashboardTitle />

          <div className='white_box dash-result-block'>
            <div className='box-title'>
              <h4>After & Before results</h4>
            </div>

            <div className='dash-result-list'>
              <Slider {...setting1} className='result-boxes-slider'>
                {sliderimg?.map((item, index) => (
                  <Col key={index} xs={12} md={4} lg={3} className='items'>
                    <div className='card-result-dash'>
                      <div className="imgbox">
                        <img src={item.image_url} alt='' />
                      </div>
                    </div>
                  </Col>
                ))}
              </Slider>
            </div>
          </div>

          <Row className='dashboard-blocks'>
            <Col xs={12} md={8} lg={8} className='left-block'>
              <div className='dash-boxes-wrapper d-flex'>
                <div className='items'>
                  <div className='card-boxes trans relative'>
                    <Link className='card-link' to='/location'></Link>
                    <i className='iconbox'>
                      <img src={box_img1} alt='' />
                    </i>
                    <h4>Book an Appointment</h4>
                  </div>
                </div>
                <div className='items'>
                  <div className='card-boxes trans relative'>
                    <Link className='card-link' onClick={handleShow} to=''></Link>
                    <i className='iconbox'>
                      <img src={box_img2} alt='' />
                    </i>
                    <h4>Order Medicine</h4>
                  </div>
                </div>
                <div className='items'>
                  <div className='card-boxes trans relative'>
                    <Link className='card-link' to='/myphotos'></Link>
                    <i className='iconbox'>
                      <img src={box_img3} alt='' />
                    </i>
                    <h4>Upload Your Photo</h4>
                  </div>
                </div>
                <div className='items'>
                  <div className='card-boxes trans relative'>
                    <Link className='card-link' to='/apply-emi'></Link>
                    <i className='iconbox'>
                      <img src={box_img4} alt='' />
                    </i>
                    <h4>EMI Options</h4>
                  </div>
                </div>
              </div>
            </Col>

            <Col xs={12} md={4} lg={4} className='right-block'>
              <div className='white_box testimonial-wrapper'>
                <div className='box-title'>
                  <h4>Recent Reviews</h4>
                </div>

                <div src={"https://cdn.trustindex.io/loader.js?906599d1520f8065485696d3394"}></div>

              </div>
            </Col>
          </Row>

          <Modal id="modalPrescription" show={show} onHide={handleClose} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body>
              <div className='appoint-booking-body ptb-20 pb-0 text-center'>
                <p className='text-20'>Do you want to buy medicine with prescription or without prescription?</p>
                <div className='btnbox ptb-20 pb-0 d-flex'>
                  <div className='btn-items' onClick={handleClose}>
                    <Link className='btn primary small' onClick={handleShow2} to=''>With Prescription</Link>
                  </div>
                  <div className='btn-items'>
                    <Link className='btn secondary small' to='/shop'>Without Prescription</Link>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          <Modal id="modalPreDoc" show={show2} onHide={handleClose2} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body>
              <div className='appoint-booking-body'>
                <h2>Prescription Documents</h2>
                <Form className='form-inner emi-form-main' onSubmit={handleSubmit} ref={form}>
                  <div className='input-field full-width'>
                    <label className='label'>Upload Prescription</label>
                    <div className={`file-upload-box ${myclass1}`}>
                      <label className='upload-img1 w-100'>
                        <Input className='upload-pre' type="file" accept=".pdf, .doc, .docx" onChange={handleChange1} />
                        <div className='drag-drop-box d-flex'>
                          <i className="las la-file-pdf"></i><span>Drag & Drop or Choose file to upload</span>
                        </div>
                        <div className='uploaded-files'>
                          <i className="las la-file"></i>{file1}
                        </div>
                      </label>
                    </div>
                    <small className='allow-file-label label mb-0 pt-2'>Only ( .pdf, .doc, .docx ) are allowed</small>
                    {fileError && <div className="alert alert-danger mt-3" role="alert">{fileError}</div>}
                  </div>

                  <div className='input-field full-width mb-0 d-flex'>
                    <div className='btnbox pt-0 d-flex v-center'>
                      <button type='submit' className='btn secondary'>Submit</button>
                      <Link onClick={handleClose2} className='btn primary ml-2'>Cancle</Link>
                    </div>
                  </div>
                </Form>
              </div>
            </Modal.Body>
          </Modal>

          <Modal id="modalThankyou" show={show3} onHide={handleClose3} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body>
              <div className='appoint-booking-body text-center thankyou-inn'>
                <div className='thankyou-innbox'>
                  <i className='thankyou-iconbox d-flex'><img src={thankyou_icon} alt='' /></i>
                  <h3><strong>Thank you for the prescription.</strong></h3>
                  <p>We will update your cart soon and notify you via email.</p>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </section>
    </div>
  )
}
export default Dashboard